<template>
  <!-- 주차장 제보 내역 중 미답변 테이블 페이지 -->
  <div>
    <h4 class="listTitle">주차장 제보 내역</h4>

    <div class="tip-btn">
      <button class="black-btn" v-if="!isEditing" @click="goToLotTipPage">←</button>
      <button class="blue-btn" @click="submitAnswer">답변등록</button>
    </div>

    <div class="tip-content">
      <div class="tip-table-wrapper">
        <table class="tip-table">
          <colgroup>
            <col style="width: 20%;" />
            <col style="width: 80%;" />
          </colgroup>

          <tbody>
            <tr>
              <th>주차장 주소</th>
              <td>{{ noticeData.address || '-' }}</td>
            </tr>
            <tr>
              <th>주차장 이름</th>
              <td>{{ noticeData.parkingName || '-' }}</td>
            </tr>
            <tr>
              <th>요금 정보</th>
              <td>{{ noticeData.firstMinute ? `${noticeData.firstMinute}분 기본 ${noticeData.firstFee}원, 이 후
                ${noticeData.extraMinute}분당 ${noticeData.extraFee}원` : '-' }}</td>
            </tr>
            <tr>
              <th>주차장연락처</th>
              <td>{{ noticeData.contact || '-' }}</td>
            </tr>
            <tr>
              <th>주차장사진</th>
              <td>
                <a :href="noticeData.imageDir" target="_blank" download v-if="noticeData.imageDir">
                  이미지 보기
                </a>
                <span v-else>-</span>
              </td>
            </tr>
            <tr class="fill-space">
              <th>기타</th>
              <td>{{ noticeData.etc || '-' }}</td>
            </tr>
          </tbody>

        </table>
      </div>

      <!-- 답변 div -->
      <div class="tip-answer">
        <div class="textareaFixed">
          <!-- 등록 승인 라디오 버튼 -->
          <input type="radio" id="approve" value="COMPLETE" v-model="status">
          <label for="approve" style="color:#5b67ec">등록승인</label>


          <!-- 등록 거절 라디오 버튼 -->
          <input type="radio" id="reject" value="REJECTED" v-model="status">
          <label for="reject" style="color:#f93c65">등록거절</label>
        </div>

        <div class="textareaContainer ">
          <textarea class="content-input" v-model="noticeData.answer"></textarea>
        </div>
      </div>
    </div>


    <!-- 삭제모달 -->
    <b-modal v-model="모달창열렸니" hide-footer hide-header centered>
      <div class="modalContents">
        <div class="modalImageWrapper">
          <img src="@/assets/icons/Vector.svg" alt="" class="modalCar" />
        </div>
        <div class="modalTextWrapper">
          <p class="mainAnswer">제보내역을 삭제하시겠습니까?</p>
          <p>삭제된 제보내역은 복구되지 않습니다</p>
        </div>
        <div class="modalBtn">
          <b-button class="modal-blue-btn" @click="deleteItem">네</b-button>
          <b-button class="modal-red-btn" @click="모달창열렸니 = false">아니오</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  name: "tipDetail00",
  props: ['id'],
  data() {
    return {
      모달창열렸니: false,
      등록모달창열렸니: false,
      noticeData: null,
      // approveChecked: false,  // 등록 승인 체크 여부
      // rejectChecked: false,   // 등록 거절 체크 여부
      status: '',
      initialAnswer: '',
    };
  },
  computed: {
    selectedButton() {
      return this.$store.state.selectedButton;
    }
  },
  created() {
    if (this.$route.query.itemData) {
      this.noticeData = JSON.parse(this.$route.query.itemData);
      this.initialAnswer = this.noticeData.answer || ''; // 초기 답변 설정 
      console.log("Loaded noticeData:", this.noticeData);
    } else {
      this.$router.push("/parkinglotTip");
    }
  },
  methods: {
    goToLotTipPage() {
            this.$router.push("/parkinglotTip")
        },
        
    async submitAnswer() {

      if (!this.status) {
        Swal.fire({
          imageUrl: require('@/assets/icons/Vector.svg'),
          html: `<div style='font-size:1.5rem; margin-bottom: 1rem; font-weight:900;'>입력값을 확인하세요</div>
                    <div style='font-size:1rem; margin-top: 1rem; line-height: 1.5;'>
                      답변을 등록하시려면, <span style='color:#F93C65;'>등록 승인 여부</span>를 반드시 선택해 주세요</div>`,
          confirmButtonText: '확인',
          confirmButtonColor: '#5B67EC',
          imageWidth: 55,
          imageHeight: 55,
        });
        return;
      }

      if (!this.noticeData.answer || this.noticeData.answer.trim() === '' || this.noticeData.answer.trim() === this.initialAnswer.trim()) {
        Swal.fire({
          imageUrl: require('@/assets/icons/Vector.svg'),
          html: `<div style='font-size:1.5rem; margin-bottom: 1rem; font-weight:900;'>입력값을 확인하세요</div>
                    <div style='font-size:1rem; margin-top: 1rem; line-height: 1.5;'>
                      답변을 등록하시려면, <span style='color:#F93C65;'>답변내용</span>을 반드시 입력해 주세요</div>`,
          confirmButtonText: '확인',
          confirmButtonColor: '#5B67EC',
          imageWidth: 55,
          imageHeight: 55,
        });
        return;
      }

      try {

        if (!this.$store.state.selectedButton) {
      console.log('Vuex selectedButton이 비어 있음. 기본값 설정 중...');
      this.$store.commit('setSelectedButton', this.buttons[0]); // 기본값으로 설정
    }

    const selectedButton = this.$store.state.selectedButton;
    console.log('selectedButton:', selectedButton);

    if (!selectedButton.apiEndpoint) {
      throw new Error('API 엔드포인트가 설정되지 않았습니다.');
    }


        await this.$store.dispatch('submitAnswer', {
          // 선택된 버튼 전달
          answer: this.noticeData.answer,  // 입력된 답변 전달
          status: this.status,  // 라디오 버튼으로 설정된 상태 전달
          id: this.noticeData.id
        });
        this.$router.push('/parkinglotTip');
        Swal.fire({
          imageUrl: require('@/assets/icons/Vector.svg'),
          html: `<div style='font-size:1rem; margin-top: 1rem; line-height: 1.5;'>답변이 성공적으로 등록되었습니다</div>`,
          confirmButtonText: '확인',
          confirmButtonColor: '#5B67EC',
          imageWidth: 55,
          imageHeight: 55,
        });

      } catch (error) {
        console.error('오류 발생:', error.message);
      }
    }
  }
}

</script>

<style>
.tip-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* 두 영역이 동일한 너비를 가지도록 설정 */
  gap: 1.25rem;
  width: 100%;
  margin-left: 6.25rem;
  padding-right: 150px;
  margin-top: 1.25rem;
}

.tip-btn {
  display: flex;
  gap: 0.625rem;
  width: 100%;
  margin-left: 6.25rem;
  padding-right: 6.25rem;
  margin-top: 40px;
}

.tip-table-wrapper,
.tip-answer {
  height: 100%;
  border-radius: 1rem;
}

.tip-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 1.125rem;
  overflow: hidden;
  min-height: 100%;
  background-color: white;
  border: 1px solid #6c757d;
}

.tip-table th,
.tip-table td {
  padding-right: 0.625rem;
  padding-left: 1.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border: 1px solid #ddd;
  text-align: left;
}

.tip-table tbody tr.fill-space td {
  height: 100%;
  vertical-align: top;
}

.tip-table tbody tr:last-of-type td {
  border-bottom: none;
}

.tip-table tbody tr:last-of-type th {
  border-bottom: none;
}

.content-input {
  width: 100%;
  margin: 1rem;
  padding: 1rem;
  border: none;
  outline: none;
  box-shadow: none;
  overflow: hidden;
  border-radius: 4px;
}

.textareaFixed {
  display: flex;
  gap: 0.625rem;
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;
  margin-left: 0.2rem;
}

.textareaContainer {
  display: flex;
  flex-direction: row;
  border: 1px #E0E0E0 solid;
  border-radius: 1rem;
  background-color: white;
  height: 100%;
}
</style>