<template>
  <div>
    <h4 class="listTitle">주차장 소유자 관리</h4>

    <div class="divLarge">
      <div class="div1">
        <div class="filterFirst">
          <select v-model="selectKeyword" style="width: 11.25rem;" class="custom-select">
            <option v-for="option in optionsKeyword" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
          <div class="search-wrapper">
            <input v-model="search" @input="handleSearchInput" class="search-input" type="text"
              placeholder="검색어를 입력하세요" />
            <button @click="emitFilterChange" type="submit" class="search-button">
              <img src="../assets/icons/SearchIcon.svg" alt="Search">
            </button>
          </div>
        </div>
      </div>
      <div class="div2">
        <div class="divsmall3">
          <button class="blue-btn" @click="triggerFileInput">소유자등록</button>
          <button class="blue-btn" @click="downloadTemplate">양식다운로드</button>
          <button class="blue-btn" @click="downloadExcel">엑셀다운로드</button>
          <!-- 삭제 버튼 클릭 시 모달 표시 -->
          <button class="red-btn" @click="모달창열렸니 = true">삭제</button>

          <!-- 삭제 확인 모달 -->
          <b-modal v-model="모달창열렸니" title="삭제 확인" hide-footer hide-header centered>
            <div class="modalContents">
              <div class="modalImageWrapper">
                <img src="@/assets/icons/Vector.svg" alt="" class="modalCar">
              </div>
              <div class="modalTextWrapper">
                <p class="mainAnswer">소유자를 삭제하시겠습니까?</p>
              </div>
              <div class="modalBtn">
                <b-button class="modal-blue-btn" @click="deleteOwnershipHandler(selectedItems)">네</b-button>
                <b-button class="modal-red-btn" @click="모달창열렸니 = false">아니오</b-button>
              </div>
            </div>
          </b-modal>
        </div>
        <div class="divsmall">
          <select v-model="selectedRegion" class="custom-select" @change="emitFilterChange" style="width: 8rem;">
            <option v-for="option in regionOptions" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
          <select v-model="selectedCity" class="custom-select" @change="emitFilterChange" style="width: 8rem;">
            <option v-for="option in filteredOptions2" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <input type="file" ref="fileInput" @change="fileUpload" style="display: none;">

    <basicTable :items="filteredItems" :columns="visibleColumns" :fields="fields" @rowClick="handleRowClick"
      v-model="selectedItems" @update-selected-items="setSelectedData" :keyField="'ownerId'" />

  </div>
</template>


<script>
import { regionOptions, cityOptions } from '../assets/data.js';
import { mapState, mapActions } from 'vuex';
import basicTable from "../components/table.vue";
import * as XLSX from 'xlsx';


export default {
  name: 'ownerManage',
  components: {
    basicTable,
  },
  data() {
    return {
      regionOptions: regionOptions,
      cityOptions: cityOptions,
      selectedRegion: null,
      selectedCity: null,
      모달창열렸니: false,
      columns: [
        "순번", "소유자 이름", "생년월일", "소유자 연락처", "소유자 주소", "정산은행", "정산계좌",
        "계약일", "계약 만료일", "AS 만료일", "소유자 아이디", "소유자 비밀번호", "주차장 구분",
        "주차장명", "주차장주소", "위도", "경도", "공유 주차면", "기본시간", "기본요금",
        "추가시간", "추가요금", "일 최대 요금", "계약서"
      ],
      visibleColumns: [
        { title: "순번", width: "10%" },
        { title: "소유자 이름", width: "20%" },
        { title: "소유자 연락처", width: "20%" },
        { title: "소유자 주소", width: "30%" },
        { title: "제휴 주차장 수", width: "20%" }
      ],
      fields: ["index", "ownerName", "phoneNumber", "ownerAddress", "parkingCount"],
      optionsKeyword: [
        { value: null, text: '선택 없음', disabled: true },
        { value: 'parkingName', text: '주차장 이름' },
        { value: 'ownerAddress', text: '주차장 주소' },
        { value: 'ownerName', text: '소유자 이름' },
        { value: 'phoneNumber', text: '소유자 연락처' },
      ],
      selectedItems: [], // 선택된 항목을 저장할 배열
      selectKeyword: null,
      search: '',
    };
  },
  created() {
    this.fetchOwnerList();
  },
  computed: {
    ...mapState({
      parkingList: state => state.parkingList,
      ownerList: state => state.ownerList,
    }),
    items() {
      if (!this.ownerList || this.ownerList.length === 0) {
        console.log('ownerList is empty or not loaded yet');
        return [];
      }
      return this.ownerList.map(owner => {
        const firstParkingInfo = owner.parkingInfoList && owner.parkingInfoList.length > 0
          ? owner.parkingInfoList[0]
          : {};

        return {
          ownerId: owner.ownerId,
          ownerName: owner.name,
          phoneNumber: owner.phoneNumber,
          id: firstParkingInfo.id,
          parkingName: firstParkingInfo.name,
          ownerAddress: firstParkingInfo.ownerAddress,
          parkingCount: owner.parkingInfoList.length,
          parkingInfoList: owner.parkingInfoList,
          contractStartDate: firstParkingInfo.contractStartDate,
          contractEndDate: firstParkingInfo.contractEndDate,
          asExpireDate: firstParkingInfo.asExpireDate,
          settlementBank: firstParkingInfo.settlementBank,
          settlementAccount: firstParkingInfo.settlementAccount,
          contractFileLink: firstParkingInfo.contractFileLink,
          businessName : firstParkingInfo.businessName,
          businessRegistrationNumber : firstParkingInfo.businessRegistrationNumber,
          type: 'type3'
        };
      });
    },

    processedItems() {
      return this.splitOwnerAddress(this.items);
    },
    paginatedItems() {
      const displayedItems = this.hasActiveFilters ? this.filteredItems : this.items;
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return displayedItems.slice(start, end);
    },
    hasActiveFilters() {
      return this.selectedRegion || this.selectedCity || (this.selectKeyword && this.search);
    },
    filteredItems() {
      const selectedRegionText = this.selectedRegion;
      const selectedCityText = this.selectedCity;

      return this.processedItems.filter(item => {
        const regionMatch = selectedRegionText ? item.ownerregion === selectedRegionText : true;
        const cityMatch = selectedCityText ? item.ownercity === selectedCityText : true;

        let keywordMatch = true;
        if (this.selectKeyword === 'parkingName') {
          // 주차장 이름으로 검색할 때 모든 주차장 이름을 확인
          keywordMatch = item.parkingInfoList.some(parking => {
            return parking.name && parking.name.toLowerCase().includes(this.search.toLowerCase());
          });
        } else if (this.selectKeyword) {
          const valueToFilter = item[this.selectKeyword];
          keywordMatch = valueToFilter
            ? String(valueToFilter).toLowerCase().includes(this.search.toLowerCase())
            : false;
        }

        return regionMatch && cityMatch && keywordMatch;
      });
    },
    filteredParkingList() {

      if (this.clickedData && this.clickedData.parkingInfoList) {
        return this.clickedData.parkingInfoList;
      }
      return [];
    },
    filteredOptions2() {
      // selectedRegion이 선택되지 않았을 때도 '시군구'를 기본으로 표시
      if (!this.selectedRegion) {
        return [{ value: null, text: '시군구' }];
      }
      return this.cityOptions[this.selectedRegion] || [{ value: null, text: '시군구' }];
    },
  },
  methods: {
    ...mapActions(['getOwnerList', 'getParkingList']),

    async fetchOwnerList() {
      try {
        await this.getOwnerList();
        console.log(this.ownerList);
      } catch (error) {
        console.error('에러 :', error);
      }
    },
    // 주소 나누기
    splitOwnerAddress(items) {
      return items.map(item => {
        if (!item.ownerAddress) {
          return { ...item, ownerregion: '', ownercity: '', ownerrest: '' };
        }

        const addressParts = item.ownerAddress.split(' ');
        return {
          ...item,
          ownerregion: addressParts[0] || '',
          ownercity: addressParts[1] || '',
          ownerrest: addressParts.slice(2).join(' ') || '',
        };
      });
    },

    emitFilterChange() {
      // 검색어 변경 또는 필터 선택 시 호출
      this.$forceUpdate();
    },
    handleFilterChange({ selectedRegion, selectedCity }) {
      this.selectedRegion = selectedRegion;
      this.selectedCity = selectedCity;
    },
    handleRowClick(item, index) {
      this.$emit('rowClick', item, index);
      this.clickedData = {
        ...item,
        parkingInfoList: item.parkingInfoList,
      };
      this.selectedRowIndex = index;
      this.activePanel = 'clickedInfo3';


      this.isDetailsVisible = true;

      console.log("삭제할 소유자", this.clickedData.ownerId);
      console.log("삭제할 소유자", this.clickedData.parkingName);
      console.log("삭제할 주차장ID", this.clickedData.id);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    ...mapActions(['deleteCheckOwnerList']),

    deleteOwnershipHandler() {
      // 선택된 항목들을 삭제
      if (this.selectedItems.length > 0) {
        this.$store.dispatch('deleteCheckOwnerList', this.selectedItems)
          .then(() => {
            console.log('선택된 소유자 삭제 완료:', this.selectedItems);
            this.모달창열렸니 = false;
            this.selectedItems = [];  // 선택된 항목 초기화
          })
          .catch(error => {
            console.error('소유자 삭제 중 오류 발생:', error);
          });
      } else {
        console.warn('삭제할 항목이 없습니다.');
      }
    },
    handleSearchInput() {
      this.applyFilters(); // 검색어 입력 시 필터링 적용
    },
    applyFilters() {
      this.filteredItems = this.items.filter(item => {
        const searchMatch = this.search ? item[this.selectKeyword]?.includes(this.search) : true;
        const parkingMatch = this.reserveSelect2 ? item.parkingId === this.reserveSelect2 : true;

        return searchMatch && parkingMatch;
      });
    }
    ,
    downloadTemplate() {
      const link = document.createElement('a');
      link.href = '/주차장 소유자 등록 양식_사업자추가.xlsx';
      link.download = '주차장 소유자 등록 양식.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    setSelectedData(filteredList) {
      console.log("setSelectedData 함수 호출됨"); // 함수 호출 확인용 로그
      console.log("filteredList 값:", filteredList);
      this.selectedItems = filteredList;
      console.log("소유자 관리 페이지에서 선택된 항목:", this.selectedItems);
    },

    fileUpload(event) {
      const file = event.target.files[0]; // 파일 선택
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });

          // 첫 번째 시트의 데이터를 읽어옴
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];

          // 시트의 데이터를 JSON 형식으로 변환
          const jsonData = XLSX.utils.sheet_to_json(worksheet);

          // 모든 필드를 담은 객체 생성
          const parkingOwnerInfoArray = jsonData.map(row => ({
            no: row['No'] || '',  // 번호
            ownerName: row['소유자 이름'] || '',  // 소유자 이름
            birthDate: row['생년월일'] || '',  // 생년월일
            ownerContact: row['소유자 연락처'] || '',  // 소유자 연락처
            ownerAddress: row['소유자 주소'] || '',  // 소유자 주소
            settlementBank: row['정산은행'] || '',  // 정산은행
            settlementAccount: row['정산계좌'] || '',  // 정산계좌
            contractStartDate: row['계약일'] || '',  // 계약일
            contractEndDate: row['계약 만료일'] || '',  // 계약 만료일
            asExpireDate: row['AS 만료일'] || '',  // AS 만료일
            ownerId: row['소유자 아이디'] || '',  // 소유자 아이디
            ownerPassword: row['소유자 비밀번호'] || '',  // 소유자 비밀번호
            parkingCategory: row['주차장 구분'] || '',  // 주차장 구분
            parkingName: row['주차장명'] || '',  // 주차장명
            parkingAddress: row['주차장주소'] || '',  // 주차장 주소
            latitude: row['위도'] || '',  // 위도
            longitude: row['경도'] || '',  // 경도
            deviceCount: row['공유 주차면'] || '',  // 주차면수
            firstMinute: row['기본시간'] || '',  // 기본시간
            firstFee: row['기본요금'] || '',  // 기본요금
            extraMinute: row['추가시간'] || '',  // 추가시간
            extraFee: row['추가요금'] || '',  // 추가요금
            limitFeeOfDay: row['일 최대 요금'] || '',  // 일 최대 요금
            operatingTime: row['공유시작시간'] || '',
            closingTime: row['공유종료시간'] || '',
            businessName: row['상호명'] || '', // 상호명
            businessRegistrationNumber: row['사업자등록번호'] || '' // 사업자등록번호
          }));

          // FormData 객체 생성
          const formData = new FormData();
          formData.append('registerParkingOwnerInfo', JSON.stringify(parkingOwnerInfoArray));
          formData.append('file', file);

          // Vuex 액션을 사용하여 formData를 서버에 저장
          this.$store.dispatch('addOwnerItems', formData)
            .then(() => {
              console.log('File uploaded successfully');
            })
            .catch(error => {
              console.error('Error uploading file:', error);
            });
        };

        reader.readAsArrayBuffer(file);
      }
    },
    // 엑셀 다운로드 함수
    downloadExcel() {
      const ownerList = this.ownerList || [];
      const parkingList = this.parkingList || [];


      // parkingList가 정상적으로 로드되었는지 확인
      if (!Array.isArray(parkingList) || parkingList.length === 0) {
        console.error('parkingList가 비어있거나 로드되지 않았습니다.');
        return;
      }

      const dataToExport = ownerList.flatMap(owner => {
        if (!owner.parkingInfoList || owner.parkingInfoList.length === 0) {
          return [];
        }

        return owner.parkingInfoList.map(parking => {
          // parkingList에서 해당 주차장 ID로 매칭되는 항목 찾기 (id 타입을 문자열로 변환하여 비교)
          const matchingParking = parkingList.find(p => String(p.id) === String(parking.id));

          console.log('Matching parking:', matchingParking);  // 매칭된 항목을 콘솔에 출력

          return {
            'No': parking.id || '',
            '소유자 이름': owner.name || '',
            '생년월일': owner.birthDate || '',
            '소유자 연락처': owner.phoneNumber || '',
            '소유자 주소': parking.ownerAddress || '',
            '정산은행': parking.settlementBank || '',
            '정산계좌': parking.settlementAccount || '',
            '계약일': parking.contractStartDate || '',
            '계약 만료일': parking.contractEndDate || '',
            'AS 만료일': parking.asExpireDate || '',
            '소유자 아이디': owner.ownerId || '',
            '주차장 구분': matchingParking ? matchingParking.parkingType : '',
            '주차장명': parking.name || '',
            '주차장주소': matchingParking ? matchingParking.address : parking.ownerAddress || '',
            '위도': matchingParking ? matchingParking.latitude : '',
            '경도': matchingParking ? matchingParking.longitude : '',
            '공유 주차면': matchingParking ? matchingParking.seats : parking.deviceCount || '',
            '기본시간': matchingParking ? matchingParking.firstMinute : '',
            '기본요금': matchingParking ? matchingParking.firstFee : '',
            '추가시간': matchingParking ? matchingParking.extraMinute : '',
            '추가요금': matchingParking ? matchingParking.extraFee : '',
            '일 최대 요금': matchingParking ? matchingParking.limitFeeOfDay : '',
            '공유 시작 시간': matchingParking ? matchingParking.operatingTime : '',
            '공유 종료 시간': matchingParking ? matchingParking.closingTime : '',
            '상호명': matchingParking ? matchingParking.businessName : '',
            '사업자 등록 번호': matchingParking ? matchingParking.businessRegistrationNumber : '',
          };
        });
      });

      console.log('Data to export:', dataToExport);  // 엑셀에 넣을 데이터를 출력

      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "주차장_소유자_리스트.xlsx");
      XLSX.writeFile(workbook, "주차장_소유자_리스트.xlsx");
    }
  },

  handleExcelDownload() {
    this.downloadExcel();  // 엑셀 다운로드 함수 호출
  },





}
</script>

<style>
.listTitle {
  margin-top: 50px;
  margin-left: 6.25rem;
  font-size: 1.9rem;
  font-weight: bolder;
}

.divsmall3 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.divsmall3>button {
  background-color: white;
  border-radius: 8px;
}

.content-wrapper {
  display: flex;
  gap: 0.625rem;
  margin: 0 6.25rem;
}

.basicTable {
  flex: 2;
}

.detailsInfo {
  flex: 1;
  padding: 1.25rem;
  background-color: #ffffff;
  max-width: 51.25rem;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.25rem;
  font-weight: bold;
  cursor: pointer;
  float: right;
}

.close-btn:hover {
  color: red;
}

.clickedInfo {
  padding: 1.9rem;
}

.clickedInput {
  margin-bottom: 1.25rem;
  width: 100%;
  height: 40px;
}

.changeInfo {
  width: 100%;
  justify-content: center;
  display: flex;
  gap: 0.625rem;
}
</style>