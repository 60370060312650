<template>
  <div>
    <h4 class="listTitle">주차장 제보 내역</h4>
    <div class="notice-wrapper">
      <div class="btnWrapper">
        <div class="btn-one">
  <button 
    class="blue-btn notyet-btn" 
    @click="toggleShowPending"  
    :class="{ 'active-btn': showPending }">미답변보기</button>
</div>

        <div class="btn-two">
          <button v-for="(button, index) in buttons" :key="index" :class="{ 'blue-button': button.isActive }"
            @click="toggleButtonVariant(index)">
            {{ button.label }}
          </button>
        </div>
      </div>

      <table class="noticeTable table-hover mt-3" align="center">
        <thead>
          <tr>
            <!-- <th><input type="checkbox" name="" id="" /></th> -->
            <th style="width: 5%;">순번</th>
            <th style="width: 20%;">주차장주소</th>
            <th style="width: 7%;">작성자</th>
            <th style="width: 10%;">작성 날짜</th>
            <th style="width: 7%;">답변 상태</th>
            <th style="width: 10%;">답변날짜</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in paginatedItems" :key="item.id" @click="goToDetailPage(item)">
            <!-- <td><input type="checkbox" name="" id="" /></td> -->
            <td>{{ index+1 }}</td>
            <td>{{ item.address }}</td>
            <td>{{ item.writerName }}</td>
            <td>{{ formatDate(item.createdDate) }}</td>
            <td :style="resultColor(item.status)">{{ ResultLabel(item.status) }}</td>
            <td>{{ formatDate(item.resolvedDate) }}</td>
          </tr>
        </tbody>
      </table>

      <div>
        <b-pagination class="tablePage" :per-page="perPage" v-model="currentPage" :total-rows="rows" align="center"
          aria-controls="my-table"></b-pagination>
      </div>
    </div>

    <!-- 삭제 모달 -->
    <b-modal v-model="모달창열렸니" hide-footer hide-header centered>
      <div class="modalContents">
        <div class="modalImageWrapper">
          <img src="@/assets/icons/Vector.svg" alt="" class="modalCar" />
        </div>
        <div class="modalTextWrapper">
          <p class="mainAnswer">공지사항을 삭제하시겠습니까?</p>
          <p>삭제된 공지사항은 복구되지 않습니다</p>
        </div>
        <div class="modalBtn">
          <b-button class="modal-blue-btn" @click="deleteItem">네</b-button>
          <b-button class="modal-red-btn" @click="모달창열렸니 = false">아니오</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  name: "parkinglotTip",
  data() {
    return {
      showPending: false,
      모달창열렸니: false,
      currentPage: 1,
      perPage: 20,
      selectedButton: {
      label: '신규등록',
      apiEndpoint: 'v1/parking-requests/REGISTER',
    },
      items: [],
      buttons: [
        { label: "신규등록", isActive: false, apiEndpoint: 'v1/parking-requests/REGISTER' },
        { label: "요금변경", isActive: false, apiEndpoint: 'v1/parking-requests/CHARGE' },
        { label: "폐업", isActive: false, apiEndpoint: 'v1/parking-requests/CLOSED' },
        { label: "기타", isActive: false, apiEndpoint: 'v1/parking-requests/ETC' },
      ],
      filterStatus : null,
    };
  },
  computed: {
    rows() {
      let filteredItems = this.items;

      if (this.showPending) {
        filteredItems = filteredItems.filter(item => item.status === 'PENDING');
      }

      return filteredItems.length; // 필터링된 아이템 개수를 반환
    },
    paginatedItems() {
      if (!Array.isArray(this.items) || this.items.length === 0) {
        return [];
      }

      let filteredItems = this.items;

      if (this.showPending) {
        filteredItems = filteredItems.filter(item => item.status === 'PENDING');
      }

      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return filteredItems.slice(start, end); // 필터링된 항목을 페이지네이션하여 반환
    },
  },

  methods: {
    toggleShowPending() {
      this.showPending = !this.showPending;
      console.log("Show Pending:", this.showPending); 
      this.currentPage = 1;

    },
    ResultLabel(status) {
      const statusMap = {
        COMPLETE: '승인',
        REJECTED: '거절',
        PENDING: '대기',
      };
      return statusMap[status] || '-';
    },
    resultColor(status) {
      const colorMap = {
        COMPLETE: '#5b67ec',
        REJECTED: '#F93C65',
      };
      return { color: colorMap[status] || 'black' };
    },
    toggleButtonVariant(index) {
      if (this.buttons[index].isActive) {
        this.buttons[index].isActive = false;

        const allInactive = this.buttons.every(button => !button.isActive);
        if (allInactive) {
          this.buttons[0].isActive = true; // '신규등록' 버튼 활성화
      this.selectedButton = this.buttons[0];
        }
      } else {
        this.buttons.forEach((button, i) => {
          button.isActive = i === index;
        });
        this.selectedButton = this.buttons[index];
      }
      this.$store.commit('setSelectedButton', this.selectedButton);

      // API 엔드포인트에 따라 데이터를 불러오기
      if (this.selectedButton) {
        this.fetchTipoffList();
      }
      console.log('Selected Button:', this.selectedButton); 
      console.log("store selected", this.$store.state.selectedButton)

    },
    goToDetailPage(item) {
  const routeName = item.status === 'PENDING' ? 'tipDetail00' : 'tipDetail01';
  
  this.$router.push({
    name: routeName,
    params: { id: item.id },
    query: { itemData: JSON.stringify(item) }
  });
},

    deleteItem() {
      // 삭제 로직 구현
      console.log('Item deleted');
      this.모달창열렸니 = false;
    },
    ...mapActions(['getTipoffList']),
    async fetchTipoffList() {
      try {
        if (!this.selectedButton) {
          this.selectedButton = this.buttons[0];
        }

        const apiEndpoint = this.selectedButton.apiEndpoint;
        console.log('API Endpoint in fetchTipoffList:', apiEndpoint);  
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`,
          },
        });

        console.log('API 응답 데이터:', response.data);
        this.items = response.data.list;
      } catch (error) {
        console.error('오류:', error.response ? error.response.data : error.message);
      }
    },
    formatDate(dateString) {
    if (!dateString) return '-';
    const date = new Date(dateString);
    
    const year = date.getFullYear().toString().slice(-2); // 연도의 마지막 두 자리 추출
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월 (0부터 시작하므로 +1) 두 자리로 표시
    const day = String(date.getDate()).padStart(2, '0'); // 일 두 자리로 표시

    const hours = String(date.getHours()).padStart(2, '0'); // 시간 두 자리로 표시
    const minutes = String(date.getMinutes()).padStart(2, '0'); // 분 두 자리로 표시

    return `${year}.${month}.${day}\u00A0\u00A0\u00A0${hours}:${minutes}`;
  },
  },
  mounted() {
  // '신규등록' 버튼 활성화
  this.buttons[0].isActive = true;
  this.selectedButton = this.buttons[0]; // '신규등록' 버튼 선택

  // `toggleButtonVariant`를 호출하여 초기화 동작을 통일
  this.toggleButtonVariant(0);

  // Vuex 상태 동기화
  this.$store.commit('setSelectedButton', this.selectedButton);

  // API 데이터 로드
  this.fetchTipoffList();

  // 초기화 로그 출력
  console.log('Mounted - Selected Button:', this.selectedButton);
  console.log('Mounted - Vuex selectedButton:', this.$store.state.selectedButton);
}


};
</script>

<style>
.btnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.625rem;
}

.btn-one>button {
  background-color: white;
}

.btn-two>button {
  background-color: white;
  border-radius: 0.625rem;
  height: 40px;
  width: auto;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  border: 1px solid #ced4da;
  color: black;
}

.btn-two>button.blue-button {
  border: 1px solid #5b67ec;
  color: #5b67ec;
  background-color: white;
  border-radius: 0.625rem;
  height: 40px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.btn-one {
  display: flex;
  gap: 0.625rem;
}

.btn-two {
  display: flex;
  gap: 0.625rem;
  border-radius: 1.125rem;
}
.blue-btn.active-btn {
  background-color: #5b67ec;
  color: white;
}

</style>
